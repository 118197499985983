// pages/index.js
import { withPageAuthRequired } from '@auth0/nextjs-auth0';
import DashboardLayout from 'containers/DashboardLayout/DashboardLayout';
import { useRouter } from 'next/router';

function HomePage() {
  // const { user, error, isLoading } = useContext(GlobalContext);
  const router = useRouter();
  router.push('/dashboard');
  return <DashboardLayout loading={true}></DashboardLayout>;
  //
}

export default withPageAuthRequired(HomePage);
